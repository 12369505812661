import React, { Component } from "react";
import swal from "sweetalert";
import axios from "../../../axios";
import { BrowserRouter, Route } from "react-router-dom";
import bg_hero from "../../../assets/images/hero_1.jpg";
import { Auth, Services } from "../../Service/Services";

class Abstract extends Component {
  state = {
    datasource: [],
    allow_insert: false,
    allow_hacc: false,
    loading: true,
  };

  componentDidMount = () => {
    if (Auth.isAuth() === false) {
      window.location.href = "/";
    }

    this.retrieveData();
  };

  retrieveData = () => {
    const api_url = "abstract";

    axios.get(api_url).then((res) => {
      let datasource = res.data.entities.map((item) => {
        return {
          key: item.id,
          data: {
            abstract_no: item.abstract_no,
            author: item.author,
            abstract_type_name: item.abstract_type_name,
            abstract_topic_name: item.abstract_topic_name,
            status: item.status,
            status_text: item.status_text,
            score: item.score,
            is_temp: item.is_temp,
            abstract_source: item.abstract_source,
            allow_edit: item.allow_edit,
            allow_delete: item.allow_delete,
          },
        };
      });

      const allow_insert = res.data.allow_insert;
      const allow_hacc = res.data.allow_hacc;

      this.setState({ datasource, allow_insert, allow_hacc, loading: false });
    });
  };

  deleteAbstract = (id, is_temp = false) => {
    swal({
      title: "Confirm",
      text: "คุณแน่ใจไหมที่จะทำการลบรายการนี้",
      icon: "info",
      buttons: {
        cancel: true,
        confirm: true,
      },
    }).then((res) => {
      this.setState({ loading: true });
      if (res === true) {
        let url = is_temp === true ? `abstracthacc/${id}` : `abstract/${id}`;
        axios.delete(url).then((res) => {
          this.retrieveData();
        });
      }
    });
  };

  render() {
    const datasource = this.state.datasource.map((item, index) => {
      let className = [];

      let url = item.data.is_temp ? `abstracthacc/form` : `abstract/form`;

      const editable = (
        <a
          className="btn btn-secondary btn-sm mr-2"
          href={`${url}/edit/${item.key}`}
        >
          <i className="fa fa-pencil"></i>
        </a>
      );

      const deleteable = (
        <a
          className="btn btn-danger btn-sm mr-2"
          onClick={(e) => this.deleteAbstract(item.key, item.data.is_temp)}
        >
          <i className="fa fa-trash"></i>
        </a>
      );

      const viewable = (
        <a className="btn btn-info btn-sm" href={`${url}/view/${item.key}`}>
          <i className="fa fa-eye"></i>
        </a>
      );

      if (item.data.status === "done") {
        if (item.data.score === 2) {
          className.push("abstract-passed");
        } else {
          className.push("abstract-failed");
        }
      }

      return (
        <tr key={index} className={className.join(" ")}>
          <td className="text-center">
            {item.data.abstract_no}
            {item.data.abstract_source === "hacc" && (
              <span className="d-block badge badge-warning">HACC</span>
            )}
          </td>
          <td>{item.data.author}</td>
          <td>{item.data.abstract_type_name}</td>
          <td>{item.data.abstract_topic_name}</td>
          <td className="text-center">
            {Services.htmlParse(item.data.status_text)}
          </td>
          <td className="text-center text-white pb-3">
            {item.data.allow_edit === true && editable}
            {item.data.allow_delete === true && deleteable}
            {viewable}
          </td>
        </tr>
      );
    });

    let btn_insert;
    let btn_hacc;

    if (this.state.allow_insert === true) {
      btn_insert = (
        <a
          className="btn btn-primary mb-2 float-right"
          data-toggle="tooltip"
          data-placemeent="top"
          data-original-title="Create"
          title="Create"
          href="/abstract/form"
        >
          <i className="fa fa-plus"></i> เพิ่มข้อมูล
        </a>
      );
    }

    if (this.state.allow_hacc === true) {
      btn_hacc = (
        <a
          className="btn btn-danger mx-2 mb-2 float-right"
          data-toggle="tooltip"
          data-placemeent="top"
          data-original-title="Create"
          title="Create"
          href="/abstracthacc/form"
        >
          <i className="fa fa-plus"></i> เพิ่มข้อมูล HACC
        </a>
      );
    }

    return (
      <BrowserRouter>
        <div>
          <div
            className="site-blocks-cover inner-page-cover overlay"
            style={{ backgroundImage: `url(${bg_hero})` }}
            data-aos="fade"
            data-stellar-background-ratio="0.5"
          >
            <div className="container">
              <div className="row align-items-center justify-content-center text-center">
                <div
                  className="col-md-12"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="row justify-content-center mb-4">
                    <div className="col-md-8 text-center">
                      <h1>ผลงานวิชาการ</h1>
                      {/* <p className="lead mb-5">We Make Beautiful Things</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Route
            exact
            path={`${this.props.match.path}/`}
            component={(props) => (
              <section className="site-section bg-light">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                      <form action="#" className="p-5 bg-white text-left">
                        <h2 className="h4 text-black mb-5 text-center">
                          ผลงานวิชาการ
                        </h2>
                        <div className="row form-group">
                          <div className="col-md-12 mb-3 mb-md-0">
                            {btn_insert} {btn_hacc}
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="text-center">
                                    รหัสผลงานวิชาการ
                                  </th>
                                  <th className="text-center">
                                    ขื่อเจ้าของผลงาน
                                  </th>
                                  <th className="text-center">ประเภทผลงาน</th>
                                  <th className="text-center">หมวดหมู่</th>
                                  <th className="text-center">สถานะ</th>
                                  <th className="text-center">#</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.loading === true ? (
                                  <tr>
                                    <td className="text-center" colSpan="6">
                                      Loading...
                                    </td>
                                  </tr>
                                ) : (
                                  datasource
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </section>
            )}
          />
        </div>
      </BrowserRouter>
    );
  }
}

export default Abstract;
