import FileInput from "../../../components/FileInput/FileInput";
import AbstractComments from "./AbstractComments";

const SendButton = ({ disabled, loading }) => {
  if (loading === false)
    return (
      <button className="btn btn-lg btn-block btn-primary" disabled={disabled}>
        ส่งผลงาน
      </button>
    );

  return (
    <button className="btn btn-lg btn-block btn-primary" disabled={disabled}>
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>{" "}
      Loading...
    </button>
  );
};

export default function AbstractInputForm({
  datasource,
  data,
  disabled,
  onChange,
  onFileChange,
  loading,
}) {
  const abstract_topic_options = datasource.abstract_topic
    .filter((f) => {
      return parseInt(f.abstract_type_id) === parseInt(data.abstract_type_id);
    })
    .map((item) => {
      return (
        <option key={item.key} value={item.key}>
          {item.label}
        </option>
      );
    });

  return (
    <div>
      <div className="row form-group">
        <div className="col-md-12 mb-3 mb-md-0">
          <label className="field-required text-black" htmlFor="author">
            ขื่อเจ้าของผลงาน:
          </label>
          <input
            required
            type="text"
            name="author"
            id="author"
            disabled={disabled}
            onChange={onChange}
            value={data.author}
            className="form-control"
            maxLength={1000}
          />
        </div>
      </div>
      <div className="row form-group">
        <div className="col-md-12 mb-3 mb-md-0">
          <label
            className="field-required text-black"
            htmlFor="abstract_type_id"
          >
            ประเภทผลงาน:
          </label>
          <select
            required
            id="abstract_type_id"
            name="abstract_type_id"
            disabled={disabled}
            onChange={onChange}
            value={data.abstract_type_id}
            className="form-control"
          >
            <option value="">กรุณาเลือก</option>
            {datasource.abstract_type.map((abstractType) => (
              <option key={abstractType.key} value={abstractType.key}>
                {abstractType.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      {data.abstract_type_code !== "oral" && (
        <div className="row form-group">
          <div className="col-md-12 mb-3 mb-md-0">
            <label
              className="field-required text-black"
              htmlFor="abstract_topic_id"
            >
              หมวดหมู่การนำเสนอ:
            </label>
            <select
              required
              id="abstract_topic_id"
              name="abstract_topic_id"
              disabled={disabled}
              onChange={onChange}
              value={data.abstract_topic_id}
              className="form-control"
            >
              <option value="">กรุณาเลือก</option>
              {abstract_topic_options}
            </select>
          </div>
        </div>
      )}
      <div className="row form-group">
        <div className="col-md-12 mb-3 mb-md-0">
          <label className="field-required text-black" htmlFor="name">
            ชื่อผลงาน:
          </label>
          <input
            required
            type="text"
            name="name"
            id="name"
            disabled={disabled}
            onChange={onChange}
            value={data.name}
            className="form-control"
            maxLength={1000}
          />
        </div>
      </div>
      {data.abstract_type_code === "oral" && (
        <div className="row form-group">
          <div className="col-md-12 mb-3 mb-md-0">
            <label className="text-black" htmlFor="EC">
              EC:
            </label>
            <input
              type="text"
              name="EC"
              id="EC"
              disabled={disabled}
              onChange={onChange}
              value={data.EC}
              className="form-control"
              maxLength={1000}
            />
          </div>
        </div>
      )}
      <div className="row form-group">
        <div className="col-md-12 mb-3 mb-md-0">
          <label className="field-required text-black" htmlFor="abstract_file">
            ไฟล์แนบ:
          </label>
          <FileInput
            multiple={false}
            name="abstract_file"
            disabled={disabled}
            value={data.asset_file_preview}
            required={true}
            accept="application/msword, application/pdf"
            onChange={onFileChange}
          ></FileInput>
          <small className="text-mute">
            หมายเหตุ: รองรับไฟล์ .pdf และขนาดไฟล์ไม่เกิน 700KB
          </small>
        </div>
      </div>
      <br />
      {data.comments.length > 0 && (
        <AbstractComments comments={data.comments} />
      )}
      {disabled === false && (
        <SendButton disabled={disabled} loading={loading} />
      )}
      <a className="btn btn-lg btn-block btn-seconday" href="/abstract">
        ย้อนกลับ
      </a>
    </div>
  );
}
