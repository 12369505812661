import React, { Component } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import "./App.scss";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";

import Home from "../pages/Landing/Home/Home";
import Register from "../pages/Landing/Register/Register";
import Abstract from "../pages/Landing/Abstract/Abstract";
import AbstractForm from "../pages/Landing/Abstract/AbstractForm";
import AbstractHACCForm from "../pages/Landing/Abstract/AbstractHACCForm";
import Venue from "../pages/Landing/Venue/Venue";
import News from "../pages/Landing/News/News";
import NewsDetail from "../pages/Landing/News/NewsDetail";
import Hotel from "../pages/Landing/Hotel/Hotel";
import Member from "../pages/Landing/Member/Member";
import MemberForm from "../pages/Landing/Member/MemberForm";
import MemberFormOnline from "../pages/Landing/Member/MemberFormOnline";
import MemberCancelPayment from "../pages/Landing/Member/MemberCancelPayment";
import MemberDone from "../pages/Landing/Member/MemberDone";
import Guest from "../pages/Guest/Guest";
import CheckIn from "../pages/CheckIn";
import UserChangePassword from "../pages/Landing/User/ChangePassword";
import UserProfile from "../pages/Landing/User/Profile";
import Publish from "../pages/Landing/Publish/Publish";
import Agenda from "../pages/Landing/Agenda/Agenda";
import Workshop from "../pages/Landing/Workshop/Workshop";
import Certificate from "../pages/Landing/Certificate/Certificate";
import ForgetPassword from "../pages/Landing/Authen/ForgetPassword";
import ChangePassword from "../pages/Landing/Authen/ChangePassword";
import FAQ from "../pages/Landing/FAQ/FAQ";
import Agreement from "../pages/Landing/Agreement/Agreement";
import MemberRegisterAgreement from "../pages/Landing/Agreement/MemberRegisterAgreement";

import Notfound from "../pages/Authentication/Service/404";

import { authenticationService } from "../pages/Authentication/Service/authentication.service";
import { history } from "../pages/Authentication/_helpers/";
import { Auth } from "../pages/Service/Services";

const routes = [
  {
    path: "/",
    exact: true,
    component: Home,
  },
  {
    path: "/register",
    exact: true,
    component: Register,
  },
  {
    path: "/abstract",
    exact: true,
    component: Abstract,
  },
  {
    path: "/hotel",
    exact: true,
    component: Hotel,
  },
  {
    path: "/venue/:id",
    exact: true,
    component: Venue,
  },
  {
    path: "/abstract/form/:action?/:id?",
    exact: false,
    component: AbstractForm,
  },
  {
    path: "/abstracthacc/form/:action?/:id?",
    exact: false,
    component: AbstractHACCForm,
  },
  {
    path: "/member",
    exact: true,
    component: Member,
  },
  {
    path: "/news",
    exact: true,
    component: News,
  },
  {
    path: "/news/:id",
    exact: false,
    component: NewsDetail,
  },
  {
    path: "/member/form/:id?/:action?",
    exact: false,
    component: MemberForm,
  },
  {
    path: "/member/formonline/:id?",
    exact: false,
    component: MemberFormOnline,
  },
  {
    path: "/member/earlybird/form/:id?",
    exact: false,
    component: MemberForm,
  },
  {
    path: "/member/earlybird/formonline/:id?",
    exact: false,
    component: MemberFormOnline,
  },
  {
    path: "/member/cancel/:id?",
    exact: false,
    component: MemberCancelPayment,
  },
  {
    path: "/member/thankyou",
    exact: false,
    component: MemberDone,
  },
  {
    path: "/auth/forgetpassword",
    exact: false,
    component: ForgetPassword,
  },
  {
    path: "/auth/changepassword",
    exact: false,
    component: ChangePassword,
  },
  {
    path: "/g/:token",
    exact: true,
    component: Guest,
  },
  {
    path: "/m/:token/:type?",
    exact: true,
    component: CheckIn,
  },
  {
    path: "/user/changepassword",
    exact: false,
    component: UserChangePassword,
  },
  {
    path: "/user/profile",
    exact: false,
    component: UserProfile,
  },
  {
    path: "/publishdocument/:category_id?/:subcategory_id?",
    exact: true,
    component: Publish,
  },
  {
    path: "/agenda",
    exact: true,
    component: Agenda,
  },
  {
    path: "/workshop",
    exact: true,
    component: Workshop,
  },
  {
    path: "/certificate",
    exact: true,
    component: Certificate,
  },
  {
    path: "/faq",
    exact: true,
    component: FAQ,
  },
  {
    path: "/agreement",
    exact: true,
    component: Agreement,
  },
  {
    path: "/member/agreement/:type/:earlybird?",
    exact: true,
    component: MemberRegisterAgreement,
  },
  {
    path: "*",
    component: Notfound,
    exact: true,
  },
];

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: true,
    };
  }
  componentDidMount() {
    // authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
  }

  render() {
    const user = Auth.getCurrentUser();
    const menu = routes.map((route, index) => (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        component={route.component}
      />
    ));

    const container = (
      <div className="site-wrap">
        <Navbar></Navbar>
        <Switch>{menu}</Switch>
        <Footer></Footer>
      </div>
    );

    if (user && user.acceptAgreement !== true) {
      if (!window.location.href.includes("agreement"))
        window.location.href = "/agreement";
    }

    return <Router history={history}>{container}</Router>;
  }
}
export default App;
