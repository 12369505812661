import FileInput from "../../../components/FileInput/FileInput";

var QRCode = require("qrcode.react");

export default function AbstractCertificateForm({
  data,
  onFileChange,
  onChange,
  isShowButton = true,
}) {
  const {
    publish,
    present_file_preview,
    link_video_poster,
    name_cert,
    author_cert,
    company_cert,
    telephone_cert,
    email_cert,
    coordinator_name_cert,
    coordinator_telephone_cert,
    coordinator_email_cert,
  } = data;
  const disabled = publish === true;
  let upload_poster_btn;
  let qrcode;

  if (present_file_preview) {
    const download_poster = present_file_preview.initialPreview[0];

    qrcode = <QRCode value={download_poster} />;
  }

  if (disabled === false) {
    upload_poster_btn = (
      <button
        type="submit"
        className="btn btn-lg btn-block btn-primary"
        name="poster"
      >
        บันทึกข้อมูลสำหรับออกใบประกาศนียบัตร
      </button>
    );
  }

  return (
    <div className="card mt-0 mb-3">
      <div className="card-header">ข้อมูลในการออกประกาศนียบัตร</div>
      <div className="card-body">
        <div className="text-center">{qrcode}</div>
        {/* <div className="row form-group">
          <div className="col-md-12 mb-3 mb-md-0">
            <label className="field-required text-black" htmlFor="poster_file">
              Poster:
            </label>
            <FileInput
              multiple={false}
              name="poster_file"
              id="poster_file"
              disabled={disabled}
              value={data.present_file_preview}
              required={false}
              accept="application/pdf"
              onChange={onFileChange}
            ></FileInput>
            <small className="text-mute">
              หมายเหตุ: รองรับไฟล์ .pdf และขนาดไฟล์ไม่เกิน 800 MB
            </small>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-12 mb-3 mb-md-0">
            <label className="text-black" htmlFor="link_video_poster">
              Poster Video URL:
            </label>
            <input
              type="url"
              name="link_video_poster"
              id="link_video_poster"
              disabled={disabled}
              onChange={onChange}
              value={link_video_poster}
              className="form-control"
            />
            <small className="text-mute">
              หมายเหตุ: ลิงค์วีดีโอ Poster (ถ้ามี)
            </small>
          </div>
        </div> */}
        <div className="row form-group">
          <div className="col-md-12 mb-3 mb-md-0">
            <label className="field-required text-black" htmlFor="name_cert">
              ชื่อผลงาน (สำหรับออกใบประกาศนียบัตร):
            </label>
            <input
              required
              type="text"
              name="name_cert"
              id="name_cert"
              disabled={disabled}
              onChange={onChange}
              value={name_cert}
              className="form-control"
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-12 mb-3 mb-md-0">
            <label className="field-required text-black" htmlFor="author_cert">
              ชื่อ-นามสกุล เจ้าของผลงาน (สำหรับออกใบประกาศนียบัตร):
            </label>
            <input
              required
              type="text"
              name="author_cert"
              id="author_cert"
              disabled={disabled}
              onChange={onChange}
              value={author_cert}
              className="form-control"
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-12 mb-3 mb-md-0">
            <label className="field-required text-black" htmlFor="company_cert">
              ชื่อสถาบัน (สำหรับออกใบประกาศนียบัตร):
            </label>
            <input
              required
              type="text"
              name="company_cert"
              id="company_cert"
              disabled={disabled}
              onChange={onChange}
              value={company_cert}
              className="form-control"
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-12 mb-3 mb-md-0">
            <label
              className="field-required text-black"
              htmlFor="telephone_cert"
            >
              เบอร์โทรติดต่อเจ้าของผลงาน:
            </label>
            <input
              required
              type="text"
              name="telephone_cert"
              id="telephone_cert"
              disabled={disabled}
              onChange={onChange}
              value={telephone_cert}
              className="form-control"
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-12 mb-3 mb-md-0">
            <label className="field-required text-black" htmlFor="email_cert">
              E-mail เจ้าของผลงาน:
            </label>
            <input
              required
              type="email"
              name="email_cert"
              id="email_cert"
              disabled={disabled}
              onChange={onChange}
              value={email_cert}
              className="form-control"
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-12 mb-3 mb-md-0">
            <label
              className="field-required text-black"
              htmlFor="coordinator_name_cert"
            >
              ชื่อ ผู้ประสานงาน:
            </label>
            <input
              required
              type="text"
              name="coordinator_name_cert"
              id="coordinator_name_cert"
              disabled={disabled}
              onChange={onChange}
              value={coordinator_name_cert}
              className="form-control"
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-12 mb-3 mb-md-0">
            <label
              className="field-required text-black"
              htmlFor="coordinator_telephone_cert"
            >
              เบอร์โทร ผู้ประสานงาน:
            </label>
            <input
              required
              type="text"
              name="coordinator_telephone_cert"
              id="coordinator_telephone_cert"
              disabled={disabled}
              onChange={onChange}
              value={coordinator_telephone_cert}
              className="form-control"
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-12 mb-3 mb-md-0">
            <label
              className="field-required text-black"
              htmlFor="coordinator_email_cert"
            >
              E-mail ผู้ประสานงาน:
            </label>
            <input
              required
              type="email"
              name="coordinator_email_cert"
              id="coordinator_email_cert"
              disabled={disabled}
              onChange={onChange}
              value={coordinator_email_cert}
              className="form-control"
            />
          </div>
        </div>
        {isShowButton === true && upload_poster_btn}
      </div>
    </div>
  );
}
