var HtmlToReactParser = require("html-to-react").Parser;

const AbstractComment = ({ item }) => {
  const htmlToReactParser = new HtmlToReactParser();
  const asset_file_element = htmlToReactParser.parse(item.asset_file_preview);

  return (
    <li>
      <i className="fa fa-close"></i> {item.comment} <br />
      <small>
        โดย {item.name} เวลา {item.created_date}
      </small>
      <div>{asset_file_element}</div>
    </li>
  );
};

export default function AbstractComments({ comments }) {
  if (comments.length === 0) return null;

  return (
    <div className="alert alert-danger" role="alert">
      <h3>คอมเม้นโดยผู้เชี่ยวชาญ</h3>
      <ul className="list-unstyled">
        {comments.map((item, key) => (
          <AbstractComment key={key} item={item} />
        ))}
      </ul>
    </div>
  );
}
